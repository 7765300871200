import { PlusOutlined } from '@ant-design/icons';
import { Flex, Typography, theme } from 'antd';

interface IManageCardInlineAddButtonProps {
  onTrigger: VoidFunction;
}

export function ManageCardInlineAddButton({
  onTrigger,
}: IManageCardInlineAddButtonProps) {
  const {
    token: {
      boxShadowTertiary,
      colorBgLayout,
      borderRadiusLG,
      lineWidth,
      lineType,
      colorPrimary,
    },
  } = theme.useToken();

  return (
    <Flex
      justify="center"
      align="center"
      gap={16}
      style={{
        cursor: 'pointer',
        height: '100%',
        boxShadow: boxShadowTertiary,
        borderColor: colorBgLayout,
        borderRadius: borderRadiusLG,
        borderWidth: lineWidth,
        borderStyle: lineType,
      }}
      onClick={onTrigger}
    >
      <PlusOutlined style={{ fontSize: 20, color: colorPrimary }} />
      <Typography.Title
        color="primary"
        style={{ color: colorPrimary, margin: 0 }}
        level={4}
      >
        Add card
      </Typography.Title>
    </Flex>
  );
}
