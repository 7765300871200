import { useNavigate } from 'react-router-dom';

import { Card } from 'antd';

import { ICard } from '../../../types';
import { BoardCardActions } from '../board-card-actions';
import { BoardCardContent } from '../board-card-content';
import { BoardCardHeader } from '../board-card-header';
import { BoardCardSkills } from '../board-card-skills';

type TBoardCardWrapperProps = ICard & {
  // todo: some extra types will be here
};

export function BoardCardWrapper({
  content,
  title,
  skills,
  likes,
  comments,
  boardId,
  id,
}: TBoardCardWrapperProps) {
  const navigate = useNavigate();

  const handleCardClick = (event: React.MouseEvent) => {
    const targetElement = event.target as HTMLElement;

    if (
      targetElement.closest('.no-redirect') // Class for elements where click should not trigger navigation
    ) {
      return;
    }
    navigate(`/board/${boardId}/card/${id}`);
  };

  return (
    <Card
      hoverable
      onClick={handleCardClick}
    >
      <BoardCardHeader title={title} />
      <BoardCardSkills skills={skills} />
      <BoardCardContent content={content} />
      <BoardCardActions
        likes={likes}
        comments={comments}
      />
    </Card>
  );
}
