import { Navigate, Outlet } from 'react-router-dom';

export function AuthProtectedRoutes() {
  // todo: use real auth token
  const localStorageToken = localStorage.getItem('token');

  return localStorageToken ? (
    <Outlet />
  ) : (
    <Navigate
      to="/login"
      replace
    />
  );
}
