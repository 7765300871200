import { Button, Flex } from 'antd';

// todo: improve error page
export function ErrorFallback() {
  return (
    <Flex
      style={{ margin: 32 }}
      vertical
      justify="center"
      gap={16}
      align="center"
    >
      <h2>Ooops, something went wrong :( </h2>
      <Button onClick={() => window.location.assign(window.location.origin)}>
        Refresh
      </Button>
    </Flex>
  );
}
