import { createApi } from '@reduxjs/toolkit/query/react';

import { ICard } from '../../../types';
import { baseQuery } from '../../baseQuery';

export const cardsApi = createApi({
  reducerPath: 'cards',
  baseQuery: baseQuery('cards'),
  tagTypes: ['Cards'],
  endpoints: (builder) => ({
    getBoardCards: builder.query<
      Array<ICard>,
      { boardId: Pick<ICard, 'boardId'> }
    >({
      query: ({ boardId }) => `/board/${boardId}`,
      providesTags: ['Cards'],
    }),
    addBoardCard: builder.mutation<
      ICard,
      { boardId: Pick<ICard, 'boardId'>; data: Partial<ICard> }
    >({
      query: ({ boardId, data }) => ({
        method: 'POST',
        url: `/board/${boardId}`,
        body: data,
      }),
      invalidatesTags: ['Cards'],
    }),
    getCardByID: builder.query({
      query: (id: string) => ({
        url: `/${id}`,
      }),
    }),
    addCard: builder.mutation({
      query: (card: ICard) => ({
        url: '/',
        method: 'POST',
        body: card,
      }),
    }),
    removeCardByID: builder.mutation({
      query: (id: string) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useAddCardMutation,
  useGetBoardCardsQuery,
  useGetCardByIDQuery,
  useRemoveCardByIDMutation,
  useAddBoardCardMutation,
} = cardsApi;
