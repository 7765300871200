import { Col, Image, Row, Typography, theme } from 'antd';

import { ICard } from '../../../types';

type TBoardCardContentProps = Pick<ICard, 'content'>;

export function BoardCardContent({ content }: TBoardCardContentProps) {
  const {
    token: { marginSM, borderRadiusSM },
  } = theme.useToken();

  return (
    <Row
      gutter={[16, 16]}
      style={{ marginTop: marginSM }}
    >
      <Col span={8}>
        <Image
          rootClassName="no-redirect"
          alt=""
          style={{ objectFit: 'cover', borderRadius: borderRadiusSM }}
          width="100%"
          height="96px"
        />
      </Col>
      <Col span={16}>
        <Typography.Paragraph
          ellipsis={{
            rows: 4,
            expandable: false,
          }}
        >
          {content}
        </Typography.Paragraph>
      </Col>
    </Row>
  );
}
