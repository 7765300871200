import { ChangeEventHandler } from 'react';

import { EditFilled } from '@ant-design/icons';
import { Flex, Input } from 'antd';

type TManageCardInlineTitleProps = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: string;
  showIcon?: boolean;
};

export function ManageCardInlineTitle({
  onChange,
  value,
  showIcon = true,
}: TManageCardInlineTitleProps) {
  return (
    <Flex
      align="middle"
      gap={8}
    >
      {showIcon ? <EditFilled style={{ fontSize: 20, opacity: 0.3 }} /> : null}
      <Input
        onChange={onChange}
        value={value}
        name="title"
        size="large"
        placeholder="Untitled card"
        variant="borderless"
      />
    </Flex>
  );
}
