import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Card, Flex } from 'antd';

import { useAddBoardCardMutation } from '../../../features/cards/store';
import { useNotify } from '../../../shared/hooks';
import { ICard, TSkill } from '../../../types';
import { ManageCardInlineActions } from '../manage-card-inline-actions';
import { ManageCardInlineAddButton } from '../manage-card-inline-add-button';
import { ManageCardInlineDescription } from '../manage-card-inline-description';
import { ManageCardInlineSkills } from '../manage-card-inline-skills';
import { ManageCardInlineTitle } from '../mange-card-inline-title';

type TManageBoardCardInlineWrapperProps = {
  boardId: Pick<ICard, 'boardId'>;
};

export function ManageBoardCardInlineWrapper({
  boardId,
}: TManageBoardCardInlineWrapperProps) {
  const notify = useNotify();

  const [createCardMutation, { isLoading, status }] = useAddBoardCardMutation();

  const [isInlineAddTriggered, setIsInlineAddTriggered] = useState(false);
  const [cardData, setCardData] = useState<
    Pick<ICard, 'title' | 'skills' | 'content' | 'boardId'>
  >({
    title: '',
    skills: [],
    content: '',
  });

  const isSaveDisabled = useMemo(
    () => !cardData.title.trim().length,
    [cardData.title],
  );

  const loadingStyles: CSSProperties = {
    pointerEvents: 'none',
    opacity: 0.6,
  };

  const handleOpenInlineAdd = useCallback(
    () => setIsInlineAddTriggered(true),
    [],
  );

  const handleCloseInlineAdd = useCallback(
    () => setIsInlineAddTriggered(false),
    [],
  );

  const handleInputChange = useCallback((e) => {
    const { value, name } = e.target;

    setCardData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleSkillsChange = useCallback((values: Array<string>) => {
    const dummyValues: Array<TSkill> = values.map(
      (val: string) =>
        ({
          name: val,
          id: val,
        }) as TSkill,
    );

    setCardData((prev) => ({ ...prev, skills: dummyValues }));
  }, []);

  const handleOnSaveNewCard = useCallback(() => {
    createCardMutation({ boardId, data: cardData });
  }, [boardId, cardData, createCardMutation]);

  useEffect(() => {
    if (status === 'fulfilled') {
      handleCloseInlineAdd();

      notify.success('Card created successfully!');
    }
  }, [handleCloseInlineAdd, status, notify]);

  return !isInlineAddTriggered ? (
    <ManageCardInlineAddButton onTrigger={handleOpenInlineAdd} />
  ) : (
    <Card style={{ height: '100%' }}>
      <Flex
        style={{ height: '100%', ...(isLoading ? loadingStyles : {}) }}
        gap={16}
        vertical
      >
        <ManageCardInlineTitle
          onChange={handleInputChange}
          value={cardData.title}
        />
        <ManageCardInlineDescription
          onChange={handleInputChange}
          value={cardData.content}
        />
        <ManageCardInlineSkills
          onSkillsChange={handleSkillsChange}
          value={cardData.skills}
        />
        <ManageCardInlineActions
          isSaveDisabled={isSaveDisabled}
          onSave={handleOnSaveNewCard}
          onCancel={handleCloseInlineAdd}
        />
      </Flex>
    </Card>
  );
}
