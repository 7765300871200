import { Flex, Layout, theme } from 'antd';

import { Header } from '../../components';

const { Header: AntdHeader, Content } = Layout;

export function MainLayout({ children }) {
  const {
    token: { colorBgContainer, colorBgLayout },
  } = theme.useToken();

  return (
    <Layout style={{ background: colorBgContainer }}>
      <Flex
        vertical
        gap={24}
      >
        <AntdHeader
          style={{
            background: colorBgLayout,
            padding: '0 24px',
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
          }}
        >
          <Header />
        </AntdHeader>
        <Content style={{ padding: '0 24px' }}>{children}</Content>
      </Flex>
    </Layout>
  );
}
