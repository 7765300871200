import { Card, Flex, Skeleton } from 'antd';

export function BoardCardLoader() {
  return (
    <Card>
      <Flex
        gap={16}
        vertical
      >
        <Skeleton.Input
          block
          style={{ maxWidth: 200 }}
          size="small"
        />
        <Skeleton.Input
          size="small"
          block
          style={{ maxWidth: 120 }}
        />
        <Skeleton.Input
          size="small"
          block
        />
        <Skeleton.Input
          size="small"
          block
        />
        <Flex justify="space-between">
          <Skeleton.Input size="small" />
          <Skeleton.Input size="small" />
        </Flex>
      </Flex>
    </Card>
  );
}
