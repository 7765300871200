import { fetchBaseQuery } from '@reduxjs/toolkit/query';

import { env } from '../shared/config';

const baseUrl = env.API_URL;

export const baseQuery = (basePath: string) =>
  fetchBaseQuery({
    baseUrl: `${baseUrl}/${basePath}`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');

      if (token) {
        headers.set('authorization', `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJWYWhlIiwicm9sZSI6IlVTRVIiLCJpYXQiOjE3MzA4NzcwMjAsImV4cCI6MTg2MDQ3NzAyMH0.5zKsvEfEZAQtIhS277u871CJm3ObES2q8Bkg24Wd-MvDg72xBUTh9y2RdstSEetQX4ZGJ7qzwUxbgYN-FoVgqQ`);
      }

      return headers;
    },
  });
