import ReactDOM from 'react-dom/client';

import { ConfigProvider } from 'antd';

import { AppProvider } from './AppProvider';
import './global-styles/reset.css';
import './localization/i18n';
import { AppRoutes } from './routes';
import './shared/config/sentry';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <ConfigProvider theme={{ cssVar: true }}>
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  </ConfigProvider>,
);
