import { ChangeEventHandler } from 'react';

import { Input } from 'antd';

type TManageCardAdvancedTitleProps = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: string;
};

export function ManageCardAdvancedTitle({
  onChange,
  value,
}: TManageCardAdvancedTitleProps) {
  return (
    <Input
      style={{ padding: 0, marginTop: 16 }}
      onChange={onChange}
      value={value}
      name="title"
      size="large"
      placeholder="Untitled card"
      variant="borderless"
    />
  );
}
