import { Flex } from 'antd';

import { BoardHeaderSections } from './board-header-sections';

export function BoardHeader() {
  return (
    <Flex
      gap="middle"
      align="center"
    >
      {/* todo: disable for now, need to decide ux */}
      {/* <BoardHeaderTitle /> */}
      <BoardHeaderSections />
    </Flex>
  );
}
