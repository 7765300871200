import { Select } from 'antd';

import { TSkill } from '../../../types';

type TManageCardAdvancedSkillsProps = {
  onSkillsChange: (value: string[]) => void;
  value?: Array<TSkill>;
};

export function ManageCardAdvancedSkills({
  onSkillsChange,
  value,
}: TManageCardAdvancedSkillsProps) {
  const dummyOptions = [
    {
      label: 'Javascript',
      value: 'Javascript',
    },
    {
      label: 'Kotlin',
      value: 'Kotlin',
    },
    {
      label: 'Typescript',
      value: 'Typescript',
    },
    {
      label: 'NodeJS',
      value: 'NodeJS',
    },
  ];

  return (
    <Select
      variant="borderless"
      size="large"
      mode="multiple"
      onChange={onSkillsChange}
      value={value?.map((item) => item.name)}
      allowClear
      style={{ width: '100%', padding: 0 }}
      placeholder="Add skills"
      options={dummyOptions}
    />
  );
}
