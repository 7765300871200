import {
  FlagOutlined,
  HeartOutlined,
  MessageOutlined,
  PushpinOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import { Button, Col, Flex, Row, Typography, theme } from 'antd';

import { ICard } from '../../../types';

type TBoardCardActionsProps = Pick<ICard, 'likes' | 'comments'>;

export function BoardCardActions({ likes, comments }: TBoardCardActionsProps) {
  const {
    token: { marginSM },
  } = theme.useToken();

  return (
    <Row
      align="middle"
      style={{ marginTop: marginSM }}
    >
      <Col span={12}>
        <Flex
          align="middle"
          gap={8}
        >
          <Button
            type="text"
            size="large"
            className="no-redirect"
          >
            <HeartOutlined />
            <Typography.Text>{likes}</Typography.Text>
          </Button>
          <Button
            type="text"
            size="large"
            className="no-redirect"
          >
            <MessageOutlined />
            <Typography.Text>{comments}</Typography.Text>
          </Button>
        </Flex>
      </Col>
      <Col span={12}>
        <Flex
          align="middle"
          gap={8}
          justify="end"
        >
          <Button
            type="text"
            size="large"
            className="no-redirect"
          >
            <FlagOutlined />
          </Button>
          <Button
            type="text"
            size="large"
            className="no-redirect"
          >
            <ShareAltOutlined />
          </Button>
          <Button
            type="text"
            size="large"
            className="no-redirect"
          >
            <PushpinOutlined />
          </Button>
        </Flex>
      </Col>
    </Row>
  );
}
