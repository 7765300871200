import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import {
  createReduxEnhancer,
  init,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
} from '@sentry/react';

init({
  dsn: 'https://3b1d6e7f9ecd28786e1ac3637a6b81f3@o4507624367063040.ingest.us.sentry.io/4507624368504832',
  integrations: [
    reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    replayIntegration(),
  ],

  tracesSampleRate: 1.0,

  // tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

export const sentryReduxEnhancer = createReduxEnhancer();
