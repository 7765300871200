import { Suspense, lazy } from 'react';

import { Button, Flex, Modal } from 'antd';

import { ManageCardAdvancedSkills } from './manage-card-advanced-skills';
import { ManageCardAdvancedTitle } from './manage-card-advanced-title';

const ManageCardAdvancedDescription = lazy(
  () =>
    import('./manage-card-advanced-description/ManageCardAdvancedDescription'),
);

export function ManageCardAdvanced({ onClose, isOpen }) {
  return (
    <Modal
      open={isOpen}
      title="Create card"
      onCancel={onClose}
      centered
      footer={[
        <Button
          type="default"
          onClick={onClose}
          key="close"
        >
          Close
        </Button>,
        <Button
          type="primary"
          onClick={onClose}
          key="save"
        >
          Save
        </Button>,
      ]}
    >
      <Flex
        vertical
        gap={16}
      >
        <ManageCardAdvancedTitle
          value=""
          onChange={() => {}}
        />
        <Suspense>
          <ManageCardAdvancedDescription />
        </Suspense>
        <ManageCardAdvancedSkills onSkillsChange={() => {}} />
      </Flex>
    </Modal>
  );
}
