export enum RoleKeys {
  ADMIN = 'admin',
  USER = 'user',
}

export enum SettingKeys {
  role = 'role',
}

export type ISettingsState = {
  role: RoleKeys;
};

export interface SetValuePayload {
  key: SettingKeys;
  value: RoleKeys;
}
