import { ChangeEventHandler } from 'react';

import { FontSizeOutlined } from '@ant-design/icons';
import { Flex, Input } from 'antd';

type TManageCardInlineDescriptionProps = {
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  value: string;
};

export function ManageCardInlineDescription({
  onChange,
  value,
}: TManageCardInlineDescriptionProps) {
  return (
    <Flex
      align="middle"
      gap={8}
    >
      <FontSizeOutlined style={{ fontSize: 20, opacity: 0.3 }} />
      <Input.TextArea
        onChange={onChange}
        value={value}
        name="content"
        autoSize={{ minRows: 1, maxRows: 2 }}
        size="large"
        variant="borderless"
        placeholder="Card description"
        maxLength={300}
      />
    </Flex>
  );
}
