import { Button, Flex, Tooltip } from 'antd';

type TBoardActionbarItemProps = {
  icon: JSX.Element;
  tooltip: string;
  onClick?: VoidFunction;
};

export function BoardActionbarItem({
  icon,
  tooltip,
  onClick,
}: TBoardActionbarItemProps) {
  return (
    <Flex
      justify="center"
      align="center"
      gap="middle"
      vertical
    >
      <Tooltip
        placement="right"
        title={tooltip}
      >
        <Button
          onClick={onClick}
          shape="default"
          icon={icon}
          size="large"
        />
      </Tooltip>
    </Flex>
  );
}
