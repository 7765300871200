import { useCallback, useEffect, useRef, useState } from 'react';

import { CheckCircleOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Button, Flex, Input, Typography } from 'antd';
import cx from 'classnames';
import { useOnClickOutside } from 'usehooks-ts';

import { useAddBoardMutation } from '../../../features/boards/store';
import { useNotify } from '../../../shared/hooks';
import styles from './styles.module.scss';

export function BoardHeaderSectionCreate() {
  const notify = useNotify();

  const [createBoard, { isSuccess, isLoading }] = useAddBoardMutation();

  const ref = useRef<any>();
  const boardTitleRef = useRef<any>('');

  const [isNewBoardInputOpen, setIsNewBoardInputOpen] = useState(false);

  useOnClickOutside(ref, () => setIsNewBoardInputOpen(false));

  const handleCreateBoard = useCallback(() => {
    createBoard({ name: boardTitleRef.current.input.value });
  }, [createBoard]);

  const handleNewButtonClick = useCallback(
    () => setIsNewBoardInputOpen(true),
    [],
  );

  useEffect(() => {
    if (isSuccess && !isLoading) {
      setIsNewBoardInputOpen(false);

      notify.success('Board created successfully!');
    }
  }, [isLoading, isSuccess, notify]);

  return isNewBoardInputOpen ? (
    <Flex
      ref={ref}
      align="center"
      gap={8}
      className={cx(styles.wrapper, styles.sectionInput)}
    >
      <Input
        autoFocus
        variant="borderless"
        placeholder="Board name..."
        ref={boardTitleRef}
      />
      <Button
        shape="circle"
        type="primary"
        onClick={handleCreateBoard}
      >
        <CheckCircleOutlined />
      </Button>
    </Flex>
  ) : (
    <Button
      onClick={handleNewButtonClick}
      className={cx(styles.wrapper, styles.sectionLink, styles.sectionButton)}
    >
      <PlusCircleFilled />
      <Typography.Text strong>New</Typography.Text>
    </Button>
  );
}
