import { Flex, Tag, theme } from 'antd';

import { ICard } from '../../../types';

type TBoardCardSkillsProps = Pick<ICard, 'skills'>;

export function BoardCardSkills({ skills }: TBoardCardSkillsProps) {
  const {
    token: { marginSM },
  } = theme.useToken();

  return (
    <Flex
      style={{ marginTop: marginSM }}
      gap="4px 0"
      wrap
    >
      {skills?.map((skill) => (
        <Tag
          key={skill.id}
          color={skill.color}
        >
          {skill.name}
        </Tag>
      ))}
    </Flex>
  );
}
