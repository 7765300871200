import { RiseOutlined } from '@ant-design/icons';
import { Flex, Select } from 'antd';

import { TSkill } from '../../../types';

type TManageCardInlineSkillsProps = {
  onSkillsChange: (value: string[]) => void;
  value?: Array<TSkill>;
};

export function ManageCardInlineSkills({
  onSkillsChange,
  value,
}: TManageCardInlineSkillsProps) {
  const dummyOptions = [
    {
      label: 'Javascript',
      value: 'Javascript',
    },
    {
      label: 'Kotlin',
      value: 'Kotlin',
    },
    {
      label: 'Typescript',
      value: 'Typescript',
    },
    {
      label: 'NodeJS',
      value: 'NodeJS',
    },
  ];

  return (
    <Flex
      align="middle"
      gap={8}
    >
      <RiseOutlined style={{ fontSize: 20, opacity: 0.3 }} />
      <Select
        variant="borderless"
        size="large"
        mode="multiple"
        onChange={onSkillsChange}
        value={value?.map((item) => item.name)}
        allowClear
        style={{ width: '100%' }}
        placeholder="Add skills"
        defaultValue={['Javascript']}
        options={dummyOptions}
      />
    </Flex>
  );
}
