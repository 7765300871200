import { Flex } from 'antd';

import { useGetBoardSectionsQuery } from '../../../features/boards/store';
import {
  BoardHeaderSection,
  BoardHeaderSectionCreate,
} from '../board-header-section';
import styles from './styles.module.scss';

export function BoardHeaderSections() {
  const { data } = useGetBoardSectionsQuery([]);

  return (
    <Flex
      gap="middle"
      className={styles.wrapper}
    >
      {data?.map((section) => (
        <BoardHeaderSection
          key={section.id}
          {...section}
        />
      ))}
      <BoardHeaderSectionCreate />
    </Flex>
  );
}
