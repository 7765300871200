import { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Typography } from 'antd';
import cx from 'classnames';

import { TBoardSection } from '../../../types';
import styles from './styles.module.scss';

type TBoardHeaderSectionProps = TBoardSection;

export function BoardHeaderSection({ id, name }: TBoardHeaderSectionProps) {
  const { id: selectedID } = useParams();

  const isSelected = useMemo(() => selectedID === id, [id, selectedID]);

  return (
    <Link
      to={id}
      className={cx(
        styles.wrapper,
        styles.sectionLink,
        isSelected && styles.sectionLink_selected,
      )}
    >
      <Typography.Text strong>{name}</Typography.Text>
    </Link>
  );
}
