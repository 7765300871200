import { NavLink } from 'react-router-dom';

import { Flex } from 'antd';

import { headerNavigationItems } from '../../../shared/constants';
import styles from './styles.module.scss';

export function HeaderNavigation() {
  return headerNavigationItems.map((it) => (
    <Flex
      align="center"
      gap={4}
      key={it.id}
    >
      <NavLink
        to={it.url}
        className={styles.nav__item}
      >
        <Flex
          align="center"
          gap={8}
        >
          {it.icon}
          {it.title}
        </Flex>
      </NavLink>
    </Flex>
  ));
}
