// todo: mock env, add .env support
export const env = {
  VERSION: '0.0.1',
  SENTRY_DSN: undefined,
  NODE_ENV: 'development',
  IS_DEV: true,
  APP_NAME: 'zealoq_front',
  API_URL: process.env.REACT_APP_API_URL || 'http://localhost:8080',
  MOCK_API_URL: 'https://zealoq.com/api',
};
