import { useMemo } from 'react';

import { NotificationPlacement } from 'antd/es/notification/interface';

import { App } from 'antd';

export const useNotify = () => {
  const { notification } = App.useApp();

  const generateNotification = (text: string) => ({
    message: 'Zeel Notification',
    description: text,
    placement: 'bottomRight' as NotificationPlacement,
  });

  const notify = useMemo(
    () => ({
      success: (text: string) =>
        notification.success(generateNotification(text)),
      error: (text: string) => notification.error(generateNotification(text)),
      info: (text: string) => notification.info(generateNotification(text)),
    }),
    [notification],
  );

  return notify;
};
