import { EditFilled } from '@ant-design/icons';
import { Button, Col, Flex, Row, Typography } from 'antd';

import { ICard } from '../../../types';

type TBoardCardHeaderProps = Pick<ICard, 'title'>;

export function BoardCardHeader({ title }: TBoardCardHeaderProps) {
  return (
    <Row
      gutter={[16, 16]}
      align="middle"
    >
      <Col span={18}>
        <Typography.Title
          style={{ textTransform: 'capitalize' }}
          level={5}
        >
          {title}
        </Typography.Title>
      </Col>
      <Col span={6}>
        <Flex justify="end">
          <Button
            type="text"
            shape="circle"
            icon={<EditFilled />}
            className="no-redirect"
          />
        </Flex>
      </Col>
    </Row>
  );
}
