import {
  BellOutlined,
  LockOutlined,
  MessageOutlined,
  PoweroffOutlined,
  SolutionOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Badge,
  Col,
  Dropdown,
  Flex,
  Menu,
  Skeleton,
  Space,
} from 'antd';

import { useGetMyUserDataQuery } from '../../../features/user/store';

function WidgetMenu(props) {
  return (
    <Menu {...props}>
      <Menu.Item>
        <SolutionOutlined className="icon" />
        <Space style={{ marginLeft: 8 }}>Profile</Space>
      </Menu.Item>
      <Menu.Item>
        <LockOutlined className="icon" />
        <Space style={{ marginLeft: 8 }}>Change password</Space>
      </Menu.Item>
      <Menu.Item>
        <PoweroffOutlined className="icon" />
        <Space style={{ marginLeft: 8 }}>Sign out</Space>
      </Menu.Item>
    </Menu>
  );
}

export function HeaderActions() {
  const { isLoading } = useGetMyUserDataQuery({});

  return (
    <Col
      span={8}
      offset={4}
    >
      <Flex
        justify="flex-end"
        gap={24}
      >
        {isLoading ? (
          <>
            <Skeleton.Button />
            <Skeleton.Button />
            <Skeleton.Input />
          </>
        ) : (
          <>
            <Space size="middle">
              <Badge count={25}>
                <Avatar
                  shape="square"
                  icon={<MessageOutlined />}
                />
              </Badge>
            </Space>
            <Space size="middle">
              <Badge count={6}>
                <Avatar
                  shape="square"
                  icon={<BellOutlined />}
                />
              </Badge>
            </Space>

            <Flex
              align="center"
              gap={8}
            >
              <Dropdown overlay={<WidgetMenu />}>
                <Avatar
                  shape="circle"
                  icon={<UserOutlined />}
                  style={{ backgroundColor: '#f56a00' }}
                />
              </Dropdown>
            </Flex>
          </>
        )}
      </Flex>
    </Col>
  );
}
