import {
  BookOutlined,
  BranchesOutlined,
  BuildOutlined,
} from '@ant-design/icons';

type THeaderNavigationItems = {
  id: number;
  title: string;
  url: string;
  icon: JSX.Element;
};

export const headerNavigationItems: Array<THeaderNavigationItems> = [
  {
    id: 0,
    title: 'Boards',
    url: '/boards',
    icon: <BuildOutlined style={{ fontSize: 20 }} />,
  },
  {
    id: 1,
    title: 'Streams',
    url: '/streams',
    icon: <BranchesOutlined style={{ fontSize: 20 }} />,
  },
  {
    id: 2,
    title: 'My Learning',
    url: '/learning',
    icon: <BookOutlined style={{ fontSize: 20 }} />,
  },
];
