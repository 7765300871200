import { Flex, Layout, Space, theme } from 'antd';

const { Header: AntdHeader, Content } = Layout;

export function AuthLayout({ children }) {
  const {
    token: { colorBgContainer, colorBgLayout },
  } = theme.useToken();

  return (
    <Layout style={{ background: colorBgContainer }}>
      <Flex vertical>
        <AntdHeader
          style={{
            background: colorBgLayout,
            padding: '0 24px',
            position: 'fixed',
            top: 0,
            zIndex: 1,
            width: '100%',
          }}
        >
          <Space>
            <img
              src="/logo.svg"
              alt=""
            />
          </Space>
        </AntdHeader>
        <Content>{children}</Content>
      </Flex>
    </Layout>
  );
}
