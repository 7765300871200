import { createApi } from '@reduxjs/toolkit/query/react';

import { IUser } from '../../../types';
import { baseQuery } from '../../baseQuery';

export const userApi = createApi({
  reducerPath: 'user',
  baseQuery: baseQuery('boards'),
  endpoints: (builder) => ({
    getMyUserData: builder.query<{ data: IUser }, {}>({
      query: () => ({
        url: '/me',
      }),
    }),
  }),
});

export const { useGetMyUserDataQuery } = userApi;
