import { Button, Flex } from 'antd';

type TManageCardInlineActionsProps = {
  onSave: VoidFunction;
  onCancel: VoidFunction;
  isSaveDisabled: boolean;
};

export function ManageCardInlineActions({
  onSave,
  onCancel,
  isSaveDisabled,
}: TManageCardInlineActionsProps) {
  return (
    <Flex
      justify="flex-end"
      style={{ marginTop: 'auto' }}
      gap={8}
    >
      <Button
        disabled={isSaveDisabled}
        type="primary"
        onClick={onSave}
      >
        Save
      </Button>
      <Button onClick={onCancel}>Cancel</Button>
    </Flex>
  );
}
