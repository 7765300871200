import { createApi } from '@reduxjs/toolkit/query/react';

import { IBoard, ICard, TBoardSection } from '../../../types';
import { baseQuery } from '../../baseQuery';

export const boardsApi = createApi({
  reducerPath: 'boards',
  baseQuery: baseQuery('boards'),
  tagTypes: ['Boards'],
  endpoints: (builder) => ({
    getBoardSections: builder.query<Array<TBoardSection>, {}>({
      query: () => ({
        url: '',
        method: 'GET',
      }),
      providesTags: ['Boards'],
    }),
    getSectionByID: builder.query({
      query: (id: Pick<ICard, 'boardId'>) => ({
        url: `/${id}`,
      }),
    }),
    addBoard: builder.mutation({
      query: (board: Partial<IBoard>) => ({
        url: '',
        method: 'POST',
        body: board,
      }),
      invalidatesTags: ['Boards'],
    }),
    removeBoardByID: builder.mutation({
      query: (id: string) => ({
        url: `${id}`,
        method: 'DELETE',
      }),
    }),
    getBoardCardByID: builder.query({
      query: ({ boardID, cardID }) => ({
        url: `${boardID}/card/${cardID}`,
      }),
    }),
  }),
});

export const {
  useGetBoardSectionsQuery,
  useAddBoardMutation,
  useGetSectionByIDQuery,
  useRemoveBoardByIDMutation,
  useGetBoardCardByIDQuery,
} = boardsApi;
