import { lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { useRoutes } from 'react-router-dom';

import { AuthLayout, MainLayout } from '../layouts';
import { BoardLayout } from '../layouts/board-layout';
import { AuthProtectedRoutes } from './AuthProtectedRoutes';
import { LoginRoutes } from './LoginRoutes';

const BoardsPage = lazy(() => import('../pages/boards/Boards'));
const LoginPage = lazy(() => import('../pages/login/Login'));
const CardPage = lazy(() => import('../pages/card/Card'));

// todo: this is mock routes :)
function HomePage() {
  const { t } = useTranslation();

  return <div>{t('zealoq')}</div>;
}

export function AppRoutes() {
  const routes = [
    {
      path: '/',
      element: (
        <MainLayout>
          <AuthProtectedRoutes />
        </MainLayout>
      ),
      children: [
        {
          path: '/',
          element: <HomePage />,
        },
        {
          path: 'board/:boardID/card/:cardID',
          element: <CardPage />,
        },
        {
          path: '/boards',
          exact: true,
          element: <BoardLayout />,
          children: [
            {
              path: '/boards/:id',
              element: <BoardsPage />,
            },
          ],
        },
      ],
    },
    {
      path: '/',
      element: (
        <AuthLayout>
          <LoginRoutes />
        </AuthLayout>
      ),
      children: [
        {
          path: '/login',
          element: <LoginPage />,
        },
        {
          path: '*',
          element: <p>Not found</p>,
        },
      ],
    },
  ];

  const element = useRoutes(routes);

  return element;
}
