import { Outlet } from 'react-router-dom';

import { Layout, theme } from 'antd';

import { BoardActionbar } from '../../components/board-actionbar';
import { BoardHeader } from '../../components/board-header';

const { Header, Sider, Content } = Layout;

export function BoardLayout() {
  const {
    token: { colorBgLayout },
  } = theme.useToken();

  const siderStyle: React.CSSProperties = {
    // 64 is board header, 64 is app header, 24 is app header and board gap
    height: 'calc(100vh - 64px - 64px - 24px - 24px)',
    background: colorBgLayout,
    borderRadius: '0 0 16px 16px',
  };

  const contentStyle: React.CSSProperties = {
    background: '#fff',
  };

  const headerStyle: React.CSSProperties = {
    paddingLeft: 64,
    background: colorBgLayout,
    borderRadius: '16px 16px 16px 0',
    paddingRight: 0,
  };

  return (
    <Layout style={{ background: '#fff' }}>
      <Header style={headerStyle}>
        <BoardHeader />
      </Header>
      <Layout style={{ background: '#fff' }}>
        <Sider
          width={64}
          style={siderStyle}
        >
          <BoardActionbar />
        </Sider>
        <Content style={contentStyle}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}
