import { useCallback, useMemo, useState } from 'react';

import {
  CheckSquareOutlined,
  PlusSquareOutlined,
  SettingOutlined,
  ShareAltOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Flex, Skeleton } from 'antd';

import { useGetBoardSectionsQuery } from '../../features/boards/store';
import { ManageCardAdvanced } from '../manage-card-advanced';
import { BoardActionbarItem } from './board-actionbar-item';

export function BoardActionbar() {
  const [isManageCardAdvancedOpen, setIsManageCardAdvancedOpen] =
    useState(false);

  const { isLoading } = useGetBoardSectionsQuery([]);

  const handleTriggerAdvancedManageCardModal = useCallback(() => {
    setIsManageCardAdvancedOpen((prev) => !prev);
  }, []);

  const actionbarItems: Array<{
    id: number;
    tooltip: string;
    icon: JSX.Element;
    onClick?: VoidFunction;
  }> = useMemo(
    () => [
      {
        id: 0,
        tooltip: 'New card',
        onClick: handleTriggerAdvancedManageCardModal,
        icon: <PlusSquareOutlined />,
      },
      {
        id: 1,
        tooltip: 'Test 2',
        icon: <CheckSquareOutlined />,
      },
      {
        id: 2,
        tooltip: 'Test 3',
        icon: <UnorderedListOutlined />,
      },
      {
        id: 3,
        tooltip: 'Share',
        icon: <ShareAltOutlined />,
      },
      {
        id: 4,
        tooltip: 'Settings',
        icon: <SettingOutlined />,
      },
    ],
    [handleTriggerAdvancedManageCardModal],
  );

  return (
    <Flex
      gap={24}
      vertical
      align="center"
      justify="center"
      style={{ marginTop: 24 }}
    >
      <ManageCardAdvanced
        isOpen={isManageCardAdvancedOpen}
        onClose={handleTriggerAdvancedManageCardModal}
      />
      {actionbarItems.map((item) =>
        isLoading ? (
          <Skeleton.Button
            size="small"
            shape="square"
            key={item.id}
          />
        ) : (
          <BoardActionbarItem
            key={item.id}
            {...item}
          />
        ),
      )}
    </Flex>
  );
}
