import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ISettingsState, RoleKeys, SetValuePayload } from './settings.type';

export const initialStorageState: ISettingsState = {
  role: RoleKeys.ADMIN,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialStorageState,
  reducers: {
    setRole: (state, action: PayloadAction<SetValuePayload>) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
  },
});
