import { Col, Flex, Row, Space } from 'antd';

import { useGetMyUserDataQuery } from '../../features/user/store';
import { HeaderActions } from './header-actions';
import { HeaderNavigation } from './header-navigation';

export function Header() {
  useGetMyUserDataQuery({});

  return (
    <Row justify="center">
      <Col span={12}>
        <Flex gap={24}>
          <Space>
            <img
              src="/logo.svg"
              alt=""
            />
          </Space>
          <HeaderNavigation />
        </Flex>
      </Col>
      <HeaderActions />
    </Row>
  );
}
