import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { boardsApi } from '../../features/boards/store';
import { cardsApi } from '../../features/cards/store';
import { settingsSlice } from '../../features/settings/store';
import { userApi } from '../../features/user/store';
import { env } from '../config';
import { sentryReduxEnhancer } from '../config/sentry';

export const rootReducer = combineReducers({
  settings: settingsSlice.reducer,
  [boardsApi.reducerPath]: boardsApi.reducer,
  [cardsApi.reducerPath]: cardsApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
});

export const storeConfig = {
  reducer: rootReducer,
  devTools: env.IS_DEV,
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat(sentryReduxEnhancer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      boardsApi.middleware,
      cardsApi.middleware,
      userApi.middleware,
    ),
};

export const store = configureStore(storeConfig);
